import React, { ReactNode } from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { redTheme } from "@ecom/ui/styles/themes/red"
import Header from "@ecom/ui/components/Header/Halva"

import { Footer, FooterProps } from "../Footer"
import { SEOProps, SEO } from "../SEO"

export type LayoutProps = {
  noHeader?: boolean
  noFooter?: boolean
  children: ReactNode
  headerProps?: any
  seoData?: SEOProps
  shortText?: FooterProps["shortText"]
  phones?: FooterProps["phones"]
  withPhone?: FooterProps["withPhone"]
  hasCustomFooter?: boolean
}

export function Layout({
  noHeader = false,
  children,
  headerProps,
  seoData,
  phones,
  shortText,
  withPhone,
  hasCustomFooter = false,
}: LayoutProps) {
  return (
    <ThemeProvider theme={redTheme}>
      <SEO title={seoData?.title} description={seoData?.description} />
      {!noHeader && <Header {...headerProps} />}
      {children}
      {!hasCustomFooter && <Footer phones={phones} shortText={shortText} withPhone={withPhone} />}
    </ThemeProvider>
  )
}

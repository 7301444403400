import React from "react"

import UIFooter from "@ecom/ui/components/Footer"

export type FooterProps = {
  left?: JSX.Element
  phone?: string
  phones?: string[]
  shortText?: string
  restText?: string
  withPhone?: boolean
}

export function Footer({
  left,
  phone,
  phones,
  shortText,
  restText,
  withPhone = true,
}: FooterProps) {
  const ShortText = shortText && <div dangerouslySetInnerHTML={{ __html: shortText }} />
  const RestText = restText && <div dangerouslySetInnerHTML={{ __html: restText }} />

  return (
    <UIFooter
      left={left}
      phone={phone}
      phones={phones}
      shortText={ShortText}
      restText={RestText}
      withPhone={withPhone}
    />
  )
}

import React from "react"

import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export type SEOProps = {
  title?: string
  description?: string
  favicon?: string
}

export function SEO({ title, description, favicon }: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )
  const htmlAttributes = {
    lang: "ru",
  }

  const meta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: site.siteMetadata.author,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
  ]

  const link = [
    {
      rel: "icon",
      href: favicon,
      type: "image/png",
    },
  ]

  return <Helmet htmlAttributes={htmlAttributes} title={title} meta={meta} link={link} />
}
